import {useTranslation} from 'react-i18next';
import React from 'react';

export const PrivacyPolicyPage = () => {
    const {t} = useTranslation();
    return <div>
        <div className="font-title text-2xl mb-3">
            {t('privacy-policy.title')}
        </div>

        <div className="mt-5 text-gray-700">
            <div className="bold text-lg text-black">{t('privacy-policy.block-1.title')}</div>
            <div className="">{t('privacy-policy.block-1.text')}</div>
        </div>
        <div className="mt-5 text-gray-700">
            <div className="bold text-lg text-black">{t('privacy-policy.block-2.title')}</div>
            <div className="">{t('privacy-policy.block-2.text')}</div>
        </div>
        <div className="mt-5 text-gray-700">
            <div className="bold text-lg text-black">{t('privacy-policy.block-3.title')}</div>
            <div className="">{t('privacy-policy.block-3.text')}</div>
        </div>

        <div className="mt-5 text-gray-700">
            <div className="bold text-lg text-black">{t('privacy-policy.block-4.title')}</div>
            <div className="">{t('privacy-policy.block-4.text')}</div>
            <div className="mt-3">{t('privacy-policy.block-4.text-2')}</div>
        </div>
        <div className="mt-5 text-gray-700">
            <div className="bold text-lg text-black">{t('privacy-policy.block-5.title')}</div>
            <div className="">{t('privacy-policy.block-5.text')}</div>
        </div>

        <div className="mt-5 text-gray-700">
            <div className="bold text-lg text-black">{t('privacy-policy.block-6.title')}</div>
            <div className="">{t('privacy-policy.block-6.text')}</div>
        </div>

        <div className="mt-5 text-gray-700">
            <div className="bold text-lg text-black">{t('privacy-policy.block-7.title')}</div>
            <div className="">{t('privacy-policy.block-7.text')}</div>
        </div>
        <div className="mt-5 text-gray-700">
            <div className="bold text-lg text-black">{t('privacy-policy.block-8.title')}</div>
            <div className="">{t('privacy-policy.block-8.text')} <a target="_blank" className="text-red-900 hover:text-red-950 transition-all" href="https://www.cookierecht.nl/">{t('privacy-policy.block-8.link-label')}</a></div>
            <div className="mt-2">
                <div><span>{t('privacy-policy.block-8.consumentenbond')}</span>: <a target="_blank" className="text-red-900 hover:text-red-950 transition-all" href="https://www.consumentenbond.nl/internet-privacy/wat-zijn-cookies">{t('privacy-policy.block-8.cb-link-1')}</a></div>
                <div><span>{t('privacy-policy.block-8.consumentenbond')}</span>: <a target="_blank" className="text-red-900 hover:text-red-950 transition-all" href="http://www.consumentenbond.nl/test/elektronica-communicatie/veilig-online/privacy-op-internet/extra/waarvoor-dienen-cookies/">{t('privacy-policy.block-8.cb-link-2')}</a></div>
                <div><span>{t('privacy-policy.block-8.consumentenbond')}</span>: <a target="_blank" className="text-red-900 hover:text-red-950 transition-all" href="https://www.consumentenbond.nl/internet-privacy/cookies-verwijderen">{t('privacy-policy.block-8.cb-link-3')}</a></div>
                <div><span>{t('privacy-policy.block-8.consumentenbond')}</span>: <a target="_blank" className="text-red-900 hover:text-red-950 transition-all" href="https://www.consumentenbond.nl/internet-privacy/cookies-verwijderen">{t('privacy-policy.block-8.cb-link-4')}</a></div>
            </div>
        </div>

    </div>
}