import {useTranslation} from 'react-i18next';
import React from 'react';

export const ProductsProductionPage = () => {
    const {t} = useTranslation();

    return <div>

        <div className="font-title text-xl mb-3">{t('products.production.title')}</div>
        <div>
            {t('products.production.intro')}
        </div>

        <div className="mt-3">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/pdYaWxxj3u0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
    </div>
}