import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBuilding, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';
import {NavLink} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

export const Footer = () => {
    const {t} = useTranslation();

    return <div className="bg-gray-100 mt-10 ">
        <div className="mx-auto max-w-screen-lg py-10 px-4 ">
            {/*<div className="w-full h-0.5 bg-gray-200 mx-auto my-10"></div>*/}

            <div className="grid  grid-cols-1 gap-5 md:gap-1 md:grid-cols-3 items-center">
                <div className="flex flex-col md:col-span-2">
                    {/*<img src={dtsLogo} alt="" className="w-3/5"/>*/}
                    <div className="font-title text-3xl text-red-900">{t('footer.dts')}</div>
                    <div className="text-xl mt-2">{t('footer.dts-your-partner')}</div>
                </div>
                <div>
                    <div className="text-gray-700">
                        <div className="flex gap-5 items-center">
                            <div><FontAwesomeIcon icon={faBuilding}/></div>
                            <div>
                                Houtelweg 1 <br/>
                                2330 Rijkevorsel <br/>
                                België
                            </div>
                        </div>

                        <div className="flex gap-5 mt-1">
                            <div><FontAwesomeIcon icon={faPhone}/></div>
                            <div>+32 3 314 38 61</div>
                        </div>
                        {/*<div className="flex gap-5 mt-1">*/}
                        {/*    <div><FontAwesomeIcon icon={faFax}/></div>*/}
                        {/*    <div>+32 3 314 38 61</div>*/}
                        {/*</div>*/}
                        <div className="flex gap-5 mt-1">
                            <div><FontAwesomeIcon icon={faEnvelope}/></div>
                            <div>office@dts-meat.com</div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {/*<div className="w-3/5 h-0.5 bg-gray-200 mx-auto my-7"></div>*/}
                <div className="text-sm flex gap-3 justify-center text-gray-500 mt-10">
                    <NavLink className="hover:udnderline hover:text-red-900" to={"/disclaimer"}>{t('footer.disclaimer')}</NavLink>
                    <NavLink className="hover:undderline hover:text-red-900" to={"/privacy-policy"}>{t('footer.privacy-policy')}</NavLink>
                </div>
                <div className="text-gray-500 text-center text-sm mt-2 ">&copy; {new Date().getFullYear()} {t('footer.all-rights')}</div>
            </div>

        </div>
    </div>;
};