import banner from '../images/banner2.jpeg';
import halal from '../images/halal.avif';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowRight} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {NavLink} from 'react-router-dom';

// https://nicepage.com/sd/283136/solutions-improve-productivity-website-design


const HomePage = () => {
    const {t, i18n} = useTranslation();


    return <div>

        <div className=" relative ">
            <div className="md:h-96 overflow-hidden flex items-center  relative">
                <div className="bg-red-900 text-white inline-block p-10 rounded w-full md:w-3/5 z-20 relative">
                    <div className="font-title text-3xl">{t('home.title')}</div>
                    <div className="text-md mt-4">{t('home.intro')}</div>
                </div>

                <div className="absolute left-60 md:block hidden">
                    <div className="">
                        <img src={banner} alt="banner" className="bg-cover"/>
                    </div>
                </div>

            </div>
        </div>

        <div className=" md:px-4 ">
            <div className="md:mt-14 mt-5">
                <div className="mx-auto w-full md:w-3/5 border-2 border-red-900 py-5 px-4 md:px-10 rounded shadow-xl bg-white">
                    <div className="grid grid-cols-3 gap-4 md:gap-10 items-center">
                        <div className="overflow-hidden">
                            <img src={halal} alt="banner" className="bg-cover"/>
                        </div>
                        <div className=" col-span-2 ">
                            <div className="font-title text-xl mb-2">{t('home.halal.title')}</div>
                            <div>{t('home.halal.info')}</div>
                            <div className="mt-2 text-red-900 hover:text-red-950 transition-all">
                                <NavLink className=" " to={"/company/licenses"}>{t('general.more-info')}</NavLink>
                                <FontAwesomeIcon className="ml-2" icon={faArrowRight}></FontAwesomeIcon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>;
};

export default HomePage;
