import React from 'react';
import {useTranslation} from 'react-i18next';

export const CompanyLicensesPage = () => {
    const {t} = useTranslation();

    return <div>
        <div className="font-title text-2xl mb-5 ">
            {t('company.licenses.title')}
        </div>
        <div className="text-gray-700">
            <div className="bold text-lg text-black">{t('company.licenses.haccp.title')}</div>
            <div className="">{t('company.licenses.haccp.info')}</div>
            <div className="mt-2">{t('company.licenses.haccp.info2')}</div>
        </div>

        <div className="mt-5 text-gray-700">
            <div className="bold text-lg text-black">{t('company.licenses.halal.title')}</div>
            <div className="">{t('company.licenses.halal.info')}</div>
            <a target="_blank" className="text-red-900 hover:text-red-950 transition-all" href="/halal_certificaat_dts.pdf">{t('company.licenses.halal.view-certificate')}</a>
        </div>
        <div className="mt-5 text-gray-700">
            <div className="bold text-lg text-black">{t('company.licenses.ggo.title')}</div>
            <div className="">{t('company.licenses.ggo.info')}</div>
        </div>
    </div>
}