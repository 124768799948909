import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBuilding, faEnvelope, faFax, faPhone} from '@fortawesome/free-solid-svg-icons';

export const ContactPage = () => {
    const {t} = useTranslation();

    return <div>
        <div className="font-title text-2xl mb-3">
            {t('contact.title')}
        </div>


        <div className="">
            <div className="flex gap-5 items-center">
                <div><FontAwesomeIcon icon={faBuilding}/></div>
                <div>
                    Houtelweg 1 <br/>
                    2330 Rijkevorsel <br/>
                    België
                </div>
            </div>

            <div className="flex gap-5 mt-3">
                <div><FontAwesomeIcon icon={faPhone}/></div>
                <div>+32 3 314 38 61</div>
            </div>
            <div className="flex gap-5 mt-3">
                <div><FontAwesomeIcon icon={faFax}/></div>
                <div>+32 3 314 38 61</div>
            </div>
            {/*<div className="flex gap-5 mt-1">*/}
            {/*    <div><FontAwesomeIcon icon={faFax}/></div>*/}
            {/*    <div>+32 3 314 38 61</div>*/}
            {/*</div>*/}
            <div className="flex gap-5 mt-3">
                <div><FontAwesomeIcon icon={faEnvelope}/></div>
                <a className="text-red-900 hover:text-red-950 transition-all" href="mailto:office@dts-meat.com">office@dts-meat.com</a>
            </div>
        </div>
    </div>;
};