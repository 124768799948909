import {useTranslation} from 'react-i18next';

export const DisclaimerPage = () => {
    const {t} = useTranslation();

    return <div>
        <div className="font-title text-2xl mb-3">
            {t('disclaimer.title')}
        </div>
        <div>{t('disclaimer.text1')}</div>
        <div className="mt-3">{t('disclaimer.text2')}</div>

        <div>

            Disclaimer



        </div>
    </div>;
};