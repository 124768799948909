import React from 'react';
import {useTranslation} from 'react-i18next';

export const CompanyAboutUsPage = () => {
    const {t} = useTranslation();

    return <div>
        <div className="font-title text-2xl mb-5 ">
            {t('company.about-us.title')}
        </div>
        <div className="mt-3">
            {t('company.about-us.block-one')}
        </div>
        <div className="mt-3">
            {t('company.about-us.block-two')}
        </div>
        <div className="mt-3">
            {t('company.about-us.block-three')}
        </div>
    </div>;
};