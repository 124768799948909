import {useTranslation} from 'react-i18next';
import React from 'react';
import {NavLink, Outlet} from 'react-router-dom';

export const ProductsIndexPage = () => {
    const {t} = useTranslation();

    const subNavItems = [
        {name: 'materials', path: 'materials'},
        {name: 'production', path: 'production'},
        {name: 'end-products', path: 'end-products'},
    ];

    return <div>
        {/*<Tabs items={tabItems} selectedItem={tabItems[0]}></Tabs>*/}
        <div className="mb-10">
            <div className="font-title text-2xl mb-3">
                {t('products.title')}
            </div>
            <div>
                {t('products.intro')}
            </div>
        </div>
        <div className="flex border border-transparent gap-3 overflow-scroll -mt-4">
            {subNavItems.map(s => {
                return <NavLink to={s.path} className={({isActive, isPending}) =>
                    (isActive ? "text-white bg-red-900" : "text-red-900") + ' cursor-pointer px-4 block py-2 transition-all text-center rounded-xl '
                }>{t(`products.nav.${s.name}`)}</NavLink>;
            })}
        </div>
        <div className="mt-3 pt-2">
            <Outlet/>
        </div>
    </div>;
};