import {useTranslation} from 'react-i18next';
import React from 'react';
import {NavLink, Outlet} from 'react-router-dom';

export const CompanyPage = () => {
    // https://www.youtube.com/watch?v=pdYaWxxj3u0
    // https://reacttraining.com/blog/react-router-v6-pre#introducing-routes
    const {t} = useTranslation();

    const subNavItems = [
        {name: 'about-us', path: ''},
        // {name: 'our-team', path: 'our-team'},
        {name: 'licenses', path: 'licenses'},
    ];

    return <div>
        {/*<Tabs items={tabItems} selectedItem={tabItems[0]}></Tabs>*/}
        <div className="flex border border-transparent gap-3 overflow-scroll -mt-4" >
            {subNavItems.map(s => {
                return <NavLink to={s.path} end={true} className={({isActive, isPending}) =>
                    (isActive ? "text-white bg-red-900" : "text-red-900") + ' cursor-pointer px-4 block py-2 transition-all text-center rounded-xl '
                }>{t(`company.nav.${s.name}`)}</NavLink>;
            })}
        </div>
        <div className="mt-3 pt-2">
            <Outlet/>
        </div>
    </div>;
};
