import {useTranslation} from 'react-i18next';
import React from 'react';

export const ProductsGrondstoffenPage = () => {
    const {t} = useTranslation();

    return <div>
       <div>
          <div className="font-title text-xl mb-3">{t('products.materials.title')}</div>
           <ul className="list-disc ml-10">
               <li>{t('products.materials.items.borst-karkassen')}</li>
               <li>{t('products.materials.items.nekken')}</li>
               <li>{t('products.materials.items.voorruggen')}</li>
               <li>{t('products.materials.items.achterruggen')}</li>
               <li>{t('products.materials.items.borstvellen')}</li>
               <li>{t('products.materials.items.v-beentjes')}</li>
           </ul>
       </div>
    </div>
}