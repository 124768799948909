import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import HomePage from './pages/home-page';

import './i18n';
import {ContactPage} from './pages/contact-page';
import {Header} from './template/header';
import {CompanyPage} from './pages/company-page';
import {Footer} from './template/footer';
import {PrivacyPolicyPage} from './pages/privacy-policy-page';
import {DisclaimerPage} from './pages/disclaimer-page';
import {CompanyLicensesPage} from './pages/company/company-licenses-page';
import {CompanyOurTeamPage} from './pages/company/company-our-team-page';
import {CompanyAboutUsPage} from './pages/company/company-about-us-page';
import {ProductsIndexPage} from './pages/products/products-index-page';
import {ProductsProductionPage} from './pages/products/products-production-page';
import {ProductsGrondstoffenPage} from './pages/products/products-grondstoffen-page';
import {ProductsEindproductenPage} from './pages/products/products-eindproducten-page';

function App() {
    return (
        <>

            <div className="font-text">
                <Header/>
                <div className="mx-auto max-w-screen-lg px-4">
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/contact" element={<ContactPage/>}/>
                        <Route path="/company" element={<CompanyPage/>}>
                            <Route path="" index={true} element={<CompanyAboutUsPage/> }/>
                            {/*<Route path="our-team" element={<CompanyOurTeamPage/>}/>*/}
                            <Route path="licenses" element={<CompanyLicensesPage/>}/>
                        </Route>
                        <Route path="/privacy-policy" element={<PrivacyPolicyPage/>}/>
                        <Route path="/disclaimer" element={<DisclaimerPage/>}/>
                        <Route path="/products" element={<ProductsIndexPage/>}>
                            <Route path="production" element={<ProductsProductionPage/> }/>
                            <Route path="materials" element={<ProductsGrondstoffenPage/> }/>
                            <Route path="end-products" element={<ProductsEindproductenPage/> }/>

                        </Route>
                        <Route path="*" element={<Navigate replace to="/" />} />

                    </Routes>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default App;
