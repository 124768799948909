import dtsLogo from '../images/logo.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useEffect, useState} from 'react';
import {faBars, faTimes} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';
import {Link, NavLink} from 'react-router-dom';

export const Header = () => {
    const {t, i18n} = useTranslation();
    const [showMobile, setShowMobile] = useState(false);
    const [mobileIcon, setMobileIcon] = useState(faBars);
    const [currentLang, setCurrentLang] = useState(window.navigator.language);
    const routes = [
        {name: 'products', path: 'products'},
        {name: 'company', path: 'company'},
        {name: 'contact', path: 'contact'},
    ];

    const languages = ['nl', 'en'];

    useEffect(() => {
        const current = languages.find(l => {
            return window.navigator.language.indexOf(l) > -1;
        }) || window.navigator.language;

        updateLanguage(current);
    }, []);

    const updateLanguage = (lang: string) => {
        i18n.changeLanguage(lang);
        setCurrentLang(lang);
    };

    function toggleMobile() {
        setShowMobile(!showMobile);
        setMobileIcon(showMobile ? faBars : faTimes);
    }

    return <div>
        <div>
            <div className="mx-auto max-w-screen-lg px-4">
                <div className="justify-end flex ">
                    <div className="bg-red-900 rounded-b p-2 text-white">
                        {languages.map(l => {
                            return <div key={l}
                                        onClick={(event) => {
                                            updateLanguage(l);
                                        }}
                                        className={"inline-block mx-3 uppercase " + (currentLang === l ? 'text-red-300' : 'cursor-pointer')}>{l}</div>;
                        })}
                    </div>
                </div>
            </div>
        </div>
        <div className="mx-auto max-w-screen-lg mb-10">
            <div className="flex justify-between items-center mt-3 px-4">
                <Link to={"/"} className="relative w-72 md:w-auto ">
                    <img src={dtsLogo} alt="logo"/>
                </Link>

                <div className="md:hidden">
                    <div className="text-2xl" onClick={() => {
                        toggleMobile();
                    }}>
                        <FontAwesomeIcon icon={mobileIcon}></FontAwesomeIcon>
                    </div>
                </div>

                <div className="items-center gap-3 font-title text-xl hidden md:flex lowercase">
                    <>
                        {routes.map(r => {
                            return <NavLink to={"/" + r.path} key={r.path}
                                            className={({isActive, isPending}) =>
                                                (isActive ? "bg-gray-100 text-red-900" : "") + ' hover:bg-gray-100 px-4 py-2 transition-all cursor-pointer rounded'
                                            }>
                                {t(`home.nav.${r.name}`)}
                            </NavLink>;
                        })}
                    </>
                    {/*<div className="ml-2">*/}
                    {/*    <Button></Button>*/}
                    {/*</div>*/}
                </div>
            </div>

            {showMobile &&
                <div
                    className={(showMobile ? 'block' : 'hidden') + " overflow-hidden md:hidden mt-4 "}>
                    {routes.map(r => {
                        return <NavLink to={r.path} key={r.path} onClick={() => toggleMobile()}
                                        className="block px-4 py-2  border-gray-200 border border-b-0 last:border-b">{r.name}</NavLink>;
                    })}
                </div>
            }
        </div>
    </div>;
};