import React from 'react';
import {useTranslation} from 'react-i18next';

export const ProductsEindproductenPage = () => {
    const {t} = useTranslation();

    return <div>
        <div className="font-title text-xl mb-3">{t('products.end-products.title')}</div>
        <ul className="list-disc ml-10">
            <li>{t('products.end-products.items.kalkoen-mdm-1mm')}</li>
            <li>{t('products.end-products.items.kalkoen-baader-3mm')}</li>
            <li>{t('products.end-products.items.kuiken-mdm-1mm')}</li>
            <li>{t('products.end-products.items.rode-kuiken-baader-3mm')}</li>
            <li>{t('products.end-products.items.witte-kuiken-baader-3mm')}</li>
            <li>{t('products.end-products.items.kalkoen-residu')}</li>
            <li>{t('products.end-products.items.kuiken-residu')}</li>
        </ul>
        <div className="mt-3">
            {t('products.end-products.verpakking')}
        </div>
        <div className="mt-3">
            {t('products.end-products.outtro')}
        </div>
    </div>;
};